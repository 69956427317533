import Header from '@/components/header/Header.vue';
import { MenuOptions } from '@/components/Menu/menu-options';
import MenuRoot from '@/components/Menu/menu-root.vue';
import { Permission } from '@/shared/permission';
import { ssmPermissionService } from '@/shared/services/PermissionService';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import CSSModule from '@/store/modules/css-module';
import "material-design-icons/iconfont/material-icons.css";
import CargaDialog from "@/views/cargando/CargaDialog.vue"
import { medicalUserService } from '@/shared/services/user-service';
import pacienteModule from '@/store/modules/paciente-module';
import { ThemeConstants } from '@/shared/ThemeConstants';
@Component({
  components: {
    MenuRoot,
    Header,
    CargaDialog
  },
})
export default class MainLayout extends Vue {
  public fullMenuOptions = MenuOptions;
  //public appTitle = process.env.VUE_APP_TITLE;
  public route: any;

  public constructor() {
    super();
    this.bodystyle();
    Vue.prototype.$heightsection = this.$vuetify.breakpoint.height - 110;
  }
  public created() {
    this.fakePermisssions();
  }

  public get appTitle() {
    if (pacienteModule.configuracion_paciente_app) {
      window.localStorage.setItem('VUE_APP_TITLE', pacienteModule.configuracion_paciente_app.nutri_empresa);
      //this.$vuetify.theme.primary = pacienteModule.configuracion_paciente_app.nutri_color_primario;
      //this.$vuetify.theme.secondary = pacienteModule.configuracion_paciente_app.nutri_color_secundario;

      this.$vuetify.theme.themes.light.primary = pacienteModule.configuracion_paciente_app.nutri_color_primario;
      this.$vuetify.theme.themes.light.secondary = pacienteModule.configuracion_paciente_app.nutri_color_secundario;
      // this.$vuetify.theme.themes.light.accent = "#4CAF50"; // Green color
      // this.$vuetify.theme.themes.light.error = "red"; // Green color
      ThemeConstants.PRIMARY_COLOR = this.$vuetify.theme.themes.light.primary;
      ThemeConstants.SECONDARY_COLOR = this.$vuetify.theme.themes.light.secondary;
      ThemeConstants.LIGHTER_COLOR = ThemeConstants.getLighterColor(ThemeConstants.PRIMARY_COLOR);
      ThemeConstants.DARKER_COLOR = ThemeConstants.getLighterColor(ThemeConstants.PRIMARY_COLOR, 0.8);
      ThemeConstants.RGB = ThemeConstants.hexToRgb(ThemeConstants.PRIMARY_COLOR);

      // Aplica los valores como variables CSS globales
      document.documentElement.style.setProperty("--secondary", ThemeConstants.SECONDARY_COLOR);
      document.documentElement.style.setProperty("--primary", ThemeConstants.PRIMARY_COLOR);
      document.documentElement.style.setProperty("--primary-rgb", ThemeConstants.RGB);
      document.documentElement.style.setProperty("--primary-lighter", ThemeConstants.LIGHTER_COLOR);


      window.localStorage.setItem('PRIMARY_COLOR', ThemeConstants.PRIMARY_COLOR);
      window.localStorage.setItem('SECONDARY_COLOR', ThemeConstants.SECONDARY_COLOR);
      window.localStorage.setItem('LIGHTER_COLOR', ThemeConstants.LIGHTER_COLOR);
      window.localStorage.setItem('DARKER_COLOR', ThemeConstants.DARKER_COLOR);
      
      return pacienteModule.configuracion_paciente_app.nutri_empresa;
    }
    return process.env.VUE_APP_TITLE;
  }




  public get animation_type() {
    this.route = this.$route;
    let animations = ['fade', 'slide-fade', 'bounce'];
    return animations[Math.floor(Math.random() * 3)];
  }
  public async bodystyle() {
    if ((process.env.VUE_APP_PRODUCTION as string) === 'true') {
      if (this.$vuetify.breakpoint.smAndDown) {
        document.body.style.paddingRight = "0px";
      } else {
        document.body.style.overflowX = "visible";
        document.body.style.paddingRight = "0px";
      }
    }
    if (!CSSModule.cssimportados) {
      var files: string[] = [];
      // files.push('https://mymedicaldiet.es/wp-content/uploads/19.2.7.css.dx.common.css');
      // files.push('https://mymedicaldiet.es/wp-content/uploads/19.2.7.css.dx.material.orange.light.compact.css');
      //files.push('https://kit-pro.fontawesome.com/releases/v5.13.0/css/pro.min.css');
      files.push('https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css');
      for (let i = 0; i < files.length; i++) {
        /* const element = files[i];
         var styles = "@import url('" + files[i] + "');";*/
        var newSS = document.createElement('link');
        newSS.rel = 'stylesheet';
        newSS.href = files[i];//'data:text/css,' + escape(styles);
        document.getElementsByTagName("head")[0].appendChild(newSS);
      }

      CSSModule.css_importados();
    }
  }

  private fakePermisssions() {
    // Asignamos unos permisos iniciales para pruebas,
    // en un entorno real sería el back quien indicase los permisos del usuario
    const permissions: string[] = [];
    if (medicalUserService.getuser() === "app@gmail.com") {
      permissions.push(Permission.is_apple);
    } else {
      permissions.push(Permission.is_user);
    }
    ssmPermissionService.setPermissions(permissions);
  }

}
